body, html {
  height: 100%;
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: 'Share Tech Mono', monospace;
  color: #465d7a;
  background-color: #767e86;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
