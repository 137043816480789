.navbar {
  opacity: 0.3;
  transition: opacity 0.25s;
}

.navbar:hover {
  opacity: 0.9;
}

.section {
  height: 100%;
  margin: 0px auto;
  padding: 2em;
  overflow: hidden;
}

.sectionheader {
  vertical-align: middle;
  color: #686b70;
  font-size: 4vw;
  opacity: 0.6;
}

.sectioncontent {
  margin: 1em 0;
}

.Header {
  text-align: center;
  color: #c1cbd6;
  background-image: linear-gradient(#31445c, #1b2633);
}

.name {
  font-size: 5.5em;
  font-weight: bolder;
  text-shadow: -3px 3px rgb(119, 49, 167);
}

.tagline {
  font-size: 2em;
  display: inline-block;
  color: #a8b4c0;
  margin: 0 1em;
}

.greeting {
  font-weight: bolder;
  font-size: 2.5em;
  color: #e2f1ff;
  margin-top: 0.8em;
}

.smiley {
  color: #f8fa8b;
  margin: 0.5em;
}

.About {
  text-align: left;
  background-color: #dae5f2;
}

.description {
  font-size: 1.2em;
  line-height: 2;
}

#hobbies .description {
  font-weight: bolder;
  font-size: 2em;
}

.hobbieslist {
  font-size: 10em;
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.Experience {
  background-color: #ccdae9;
  text-align: left;
}

.Internship {
  display: inline-block;
  margin: 10px 30px;
}

.Internship .date {
  display: inline-block;
  color: #8da9ce;
  font-size: 2em;
  margin: 0 20px;
}

.companylogo {
  display: inline-block;
  height: 50px;
  opacity: 0.8;
  padding-bottom: 8px;
}

.companylogo:hover {
  opacity: 1;
}

.glu_logo {
  height: 75px;
}

.faire_logo {
  height: 35px;
}

.inline-title {
  display: inline-block;
  font-size: 2em;
  margin-left: 20px;
}

#experience .description {
  font-size: 1.1em;
  margin: 8px;
  margin-top: 20px;
}

.Project {
  text-align: center;
  padding: 1em;
}

.title {
  font-size: 2.5em;
}

a {
  color: #4978b6;
}

.demo {
  width: 60%;
  max-width: 500px;
  height: auto;
  border: 2px dashed #465d7a;
  border-radius: 2%;
  
}

.Projects {
  text-align: center;
  background-color: #c1cbd6;
}

.Contact {
  text-align: center;
  background-color: #9ea9b4;
}

.socialmedia {
  font-size: 3em;
}

.footer {
  background-color: #9ea9b4;
}

.icon {
  display: inline-block;
  margin: 0 0.3em;
  transition: transform .2s;
}
.icon:hover {
  transform: scale(1.2);
}

@media (max-width: 600px) {
  .sectionheader { font-size: 2.2em; }
  .name { font-size: 2.8em; }
  .tagline { font-size: 1.2em; }
  .greeting { font-size: 1.5em; }
  .description { font-size: 1em; }
  .hobbieslist { font-size: 3em; }
  .title { font-size: 1.7em; }
  .cliChessGif { width: 250px; height: auto; }
}

/* The following animations are adapted form https://daneden.github.io/animate.css/ */
@-webkit-keyframes zoomInLeft {
  from {
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(-1000px, 0, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(-1000px, 0, 0);
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }

  60% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(10px, 0, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(10px, 0, 0);
    -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
  }

  to {
    opacity: 1;
  }
}

@keyframes zoomInLeft {
  from {
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(-1000px, 0, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(-1000px, 0, 0);
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }

  60% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(10px, 0, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(10px, 0, 0);
    -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
  }

  to {
    opacity: 1;
  }
}

.zoomInLeft {
  opacity: 0;
  -webkit-animation-name: zoomInLeft;
  animation-name: zoomInLeft;
  animation-fill-mode: forwards;
  animation-duration: 1.5s;
  animation-delay: .2s;
}

@-webkit-keyframes zoomInRight {
  from {
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(1000px, 0, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(1000px, 0, 0);
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }

  60% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(-10px, 0, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(-10px, 0, 0);
    -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
  }

  to {
    opacity: 1;
  }
}

@keyframes zoomInRight {
  from {
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(1000px, 0, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(1000px, 0, 0);
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }

  60% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(-10px, 0, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(-10px, 0, 0);
    -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
  }

  to {
    opacity: 1;
  }
}

.zoomInRight {
  opacity: 0;
  -webkit-animation-name: zoomInRight;
  animation-name: zoomInRight;
  animation-fill-mode: forwards;
  animation-duration: 1.5s;
  animation-delay: .2s;
}

@-webkit-keyframes zoomInUp {
  from {
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 1000px, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 1000px, 0);
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }

  60% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
    -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
  }

  to {
    opacity: 1;
  }
}

@keyframes zoomInUp {
  from {
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 1000px, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 1000px, 0);
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }

  60% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
    -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
  }

  to {
    opacity: 1;
  }
}

.zoomInUp {
  opacity: 0;
  -webkit-animation-name: zoomInUp;
  animation-name: zoomInUp;
  animation-fill-mode: forwards;
  animation-duration: 1.5s;
  animation-delay: .2s;
}

@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.fadeIn {
  opacity: 0;
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn;
  animation-fill-mode: forwards;
  animation-duration: 1.5s;
  animation-delay: 1.5s;
}

.fadeInLater {
  opacity: 0;
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn;
  animation-fill-mode: forwards;
  animation-duration: 1.5s;
  animation-delay: 2.5s;
}
